import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

class SettingsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box>
                <Typography variant="h6" component="div">
                    Settings
                </Typography>
            </Box>
        );
    }
}

export default function Settings(props) {
    const navigate = useNavigate();
    return <SettingsPage navigate={navigate} {...props} />;
}
