import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    Link,
    Modal,
    OutlinedInput,
    Paper,
    Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import axios, { BASE_URL } from '../../api/axios';
import { StyledDataGrid } from '../../components/DataListComponents';
import { modalStyle } from '../../components/customStyles';
import { LoadingButton } from '@mui/lab';
import { CURRENCIES } from '../../data';

function WithdrawDialog(props) {
    const { token } = props;

    const withdrawal = props.withdrawal;

    const [open, setOpen] = React.useState(false);
    const [amount, setAmount] = React.useState(withdrawal['amount']);
    const [bankName, setBankName] = React.useState(withdrawal['bank']);
    const [accountNumber, setAccountNumber] = React.useState(
        withdrawal['account_no']
    );

    const [referenceCode, setReferenceCode] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    // React.useEffect(() => {
    //     if (props.withdrawal) {
    //         // if (!amount) {
    //         //     setOpen(true);
    //         //     setAccountNumber(props.widthdrawal['account_no']);
    //         //     setAmount(props.withdrawal['amount']);
    //         //     setBankName(props.widthdrawal['bank']);
    //         // }
    //         console.log(props.withdrawal)
    //             setAccountNumber(props.widthdrawal['account_no']);
    //             setAmount(props.withdrawal['amount']);
    //             setBankName(props.widthdrawal['bank']);

    //     } else {
    //         // setOpen(false);
    //     }
    // }, []);

    const handleChangeReference = (e) => {
        setReferenceCode(e.target.value);
    };

    const handleClose = () => {
        setLoading(false);
        setError('');
        setReferenceCode('');
        props.handleClose();
    };

    const handleSubmit = () => {
        setLoading(true);
        setError('');

        if (!referenceCode) {
            setError('Set Reference code');
            setLoading(false);
            return;
        }

        const options = {
            method: 'POST',
            url: `${BASE_URL}/api/manage/perform-withdraw`,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                withdraw_id: props.withdrawal.wid,
                reference_code: referenceCode
            }
        };

        axios
            .request(options)
            .then((res) => {
                setLoading(false);
                props.handleClose();
            })
            .catch((err) => {
                console.log(err);
                setError('Error occurred');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const DialogForm = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            style={modalStyle}
        >
            <Paper
                // square
                // elevation={1}
                sx={{
                    p: 0,
                    mx: 1,
                    my: 1,
                    // borderRadius: 1,
                    maxWidth: '100%',
                    minWidth: 350,
                    alignSelf: 'center',
                    justifySelf: 'center',

                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box
                    sx={{
                        p: 1
                    }}
                >
                    <Typography variant="subtitle1">Pay User</Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 1,
                        paddingX: 1,
                        paddingY: 1,
                        m: 0
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">
                            Amount
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            value={amount}
                            readOnly
                            startAdornment={
                                <InputAdornment position="start">
                                    {CURRENCIES['ng']}
                                </InputAdornment>
                            }
                            label="Amount"
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-phone">
                            Bank
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-phone"
                            readOnly
                            value={bankName}
                            label="Bank"
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-phone">
                            Account No
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-phone"
                            // disabled
                            readOnly
                            value={accountNumber}
                            label="Account No."
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-reference">
                            Reference
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-reference"
                            value={referenceCode}
                            inputRef={(input) => input && input.focus()}
                            onChange={handleChangeReference}
                            label="Reference"
                        />
                    </FormControl>
                    <Box
                        sx={{
                            p: 0,
                            m: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 1
                        }}
                    >
                        <Box
                            sx={{
                                p: 0,
                                m: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                // justifyContent: 'center',
                                gap: 1,
                                width: '100%'
                            }}
                        >
                            <LoadingButton
                                variant="contained"
                                sx={{ w: 1, flexGrow: 1 }}
                                loading={loading}
                                disableElevation
                                onClick={handleSubmit}
                            >
                                Withdraw
                            </LoadingButton>

                            <Button
                                variant="contained"
                                sx={{ w: 1, flexGrow: 1 }}
                                disableElevation
                                color="error"
                                onClick={props.handleClose}
                            >
                                Close
                            </Button>
                        </Box>

                        <Typography
                            variant="caption"
                            display="block"
                            color="red"
                        >
                            {error}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
            {/*</Container>*/}
        </Box>
    );

    // return (
    //     <Modal open={open} onClose={handleClose}>
    //         <DialogForm />
    //     </Modal>
    // );
    return (
        // <Modal open={open} onClose={handleClose}>
        <DialogForm />
        // </Modal>
    );
}

function createData(id, withdrawal, user, handleRequest) {
    return {
        id: id,
        wid: withdrawal.id,
        username: user.username,
        amount: withdrawal.amount,
        phone: withdrawal.phone_number,
        status: withdrawal.status,
        bank: withdrawal.bank,
        account_no: withdrawal.account_no,
        date: Date.parse(withdrawal.date),
        handleRequest: handleRequest
    };
}

const parseDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const dataColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 10
    },
    {
        field: 'username',
        headerName: 'Tipster',
        width: 120,
        flex: 1
    },
    {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 50,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="body1">
                {CURRENCIES['ng']} {params.row.amount.toFixed(2)}
            </Typography>
        )
    },
    {
        field: 'bank',
        headerName: 'Bank',
        minWidth: 70,
        flex: 1
    },
    {
        field: 'account_no',
        headerName: 'Account No',
        minWidth: 70,
        flex: 1
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 50,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="body1">
                {parseDate(new Date(params.row.date))}
            </Typography>
        )
    },
    {
        field: 'action',
        headerName: 'Action',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
            <Button
                variant="contained"
                disableElevation
                color="secondary"
                size="small"
                textSizeSmall
                value={params.row.wid}
                onClick={params.row.handleRequest}
            >
                Pay
            </Button>
        )
    }
];

class WithdrawRequestsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payments: [],
            loading: false,

            open: false,
            withdrawal: null,

            token: props.cookies.get('token')
        };

        this.handleLoadData = this.handleLoadData.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handlePerformRequest = this.handlePerformRequest.bind(this);
    }

    componentDidMount() {
        if (this.state.payments.length === 0) this.handleLoadData();
    }

    handleLoadData() {
        const { cookies } = this.props;
        const token = cookies.get('token');
        this.setState({
            loading: true
        });

        const options = {
            method: 'GET',
            url: `${BASE_URL}/api/manage/withdraw-request-status/P`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data['data'];
                const users = response.data['users'];
                console.log(data.length);
                this.setState({
                    payments: []
                });
                for (let i = 0; i < data.length; i++) {
                    this.setState((prev) => ({
                        payments: [
                            ...prev.payments,
                            createData(
                                i + 1,
                                data[i],
                                users[i],
                                this.handlePerformRequest
                            )
                        ]
                    }));
                }
            })
            .catch(function (error) {
                console.error(error);
            })
            .finally(() => {
                console.log(this.state.payments);
                this.setState({
                    loading: false
                });
            });
    }

    handlePerformRequest(e) {
        const id = e.target.value;
        let withdrawal = null;
        if (this.state.payments) {
            withdrawal = this.state.payments.find(
                (pay) => pay.wid === parseInt(id)
            );
        }
        if (!withdrawal) return;

        this.setState({
            open: true,
            withdrawal: withdrawal
        });
    }

    handleCloseDialog() {
        this.setState({
            open: false,
            withdrawal: null
        });
    }

    render() {
        return (
            <Box
                sx={{
                    p: 0,
                    m: 0,
                    gap: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                    // maxWidth: 700s
                }}
            >
                <Modal open={this.state.open} onClose={this.handleCloseDialog}>
                    <WithdrawDialog
                        openDialog={this.state.open}
                        handleClose={this.handleCloseDialog}
                        withdrawal={this.state.withdrawal || {}}
                        token={this.state.token}
                    />
                </Modal>

                <Paper
                    variant="outlined"
                    elevation={0}
                    sx={{
                        p: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 500
                        }}
                    >
                        {this.state.loading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: 450,
                                    m: 0
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <StyledDataGrid
                                rows={this.state.payments}
                                columns={dataColumns}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                disableSelectionOnClick
                                initialState={{
                                    sorting: {
                                        sortModel: [
                                            { field: 'date', sort: 'desc' }
                                        ]
                                    }
                                }}
                            />
                        )}
                    </Box>
                </Paper>
            </Box>
        );
    }
}

function WithdrawRequests(props) {
    return <WithdrawRequestsComponent {...props} />;
}

export default withCookies(WithdrawRequests);
