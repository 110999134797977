import React from 'react';

import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import { createGameSearch, Days, Months } from '../../data';
import { withCookies } from 'react-cookie';
import { Autocomplete } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios, { BASE_URL } from '../../api/axios';

import { matchSorter } from 'match-sorter';
import { StyledDataGrid } from '../../components/DataListComponents';

import { createData, OptionRow, dataColumns } from './AddGames/Components';

class AddGamesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchItems: [],
            options: [],
            selectedGame: null,
            searchValue: null,
            mValue: null,
            activeGames: [],
            open: false,

            loading: true,

            searching: false,

            textValue: '',
            loadingGames: false
        };

        this.handleSetOpen = this.handleSetOpen.bind(this);
        this.handleOnChangeKey = this.handleOnChangeKey.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);

        this.handleLoadTopGames = this.handleLoadTopGames.bind(this);

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.handleLoadTopGames();
    }

    handleOnChangeKey(e) {
        const pattern = e.target.value;
        this.setState({
            searchValue: pattern
        });

        console.log(pattern);
        if (!pattern) return;
        console.log('Searching');

        this.setState({
            searching: true
        });

        const options = {
            method: 'POST',
            url: `${BASE_URL}/api/manage/search-games`,
            data: {
                pattern: pattern
            }
        };

        axios
            .request(options)
            .then((response) => {
                const { games } = response.data;
                const g_data = [];
                console.log(games.length);
                for (let i = 0; i < Math.min(20, games.length); i++) {
                    const _game = games[i];
                    const game = createGameSearch(
                        _game['id'],
                        _game['home_team'],
                        _game['away_team'],
                        _game['game_date'],
                        _game['league']
                    );
                    g_data.push(game);
                }
                console.log(g_data);
                this.setState({
                    searchItems: g_data
                });
            })
            .catch(function (error) {
                console.error(error);
            })
            .finally(() => {
                this.setState({
                    searching: true
                });
            });
    }

    handleDelete(e) {
        alert(e.target.value);
        const { cookies } = this.props;
        const token = cookies.get('token');

        const options = {
            method: 'POST',
            url: `${BASE_URL}/api/manage/remove-upcoming-game/${e.target.value}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios.request(options).then((response) => {
            this.handleLoadTopGames();
        }).catch((error) => {
            console.log("Error", error.response);
            alert("Error removing game");
        })
    }

    handleLoadTopGames() {
        const { cookies } = this.props;

        const token = cookies.get('token');

        console.log('Loading top games');
        this.setState({
            loadingGames: true
        });

        const options = {
            method: 'GET',
            url: `${BASE_URL}/api/manage/get-upcoming-games`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        axios
            .request(options)
            .then((respone) => {
                const { games } = respone.data;
                this.setState({ activeGames: [] });
                for (let i = 0; i < games.length; i++) {
                    this.setState((prev) => ({
                        activeGames: [
                            ...prev.activeGames,
                            createData(i + 1, games[i], this.handleDelete)
                        ]
                    }));
                }
            })
            .catch(function (error) {
                console.error(error);
            })
            .finally(() => {
                this.setState({
                    loadingGames: false
                });
            });
    }

    handleOnSelect(e, newValue) {
        if (!newValue) {
            return;
        }
        const { id } = newValue;
        const { cookies } = this.props;

        const token = cookies.get('token');

        const options = {
            method: 'POST',
            url: `${BASE_URL}/api/manage/add-upcoming-game`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: { game_id: id }
        };

        axios
            .request(options)
            .then(function (response) {
                console.log(response.data);
                this.handleLoadTopGames();
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    handleAddUpcoming = async (gameId) => {
        const options = {
            method: 'POST',
            url: `${BASE_URL}/api/manage/add-upcoming-game`,
            header: {
                'Content-Type': 'application/json'
            },
            data: {
                game_id: gameId
            }
        };

        axios
            .request(options)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.error(error);
                alert(error.response.data.toString());
            });
    };

    handleSetOpen(value) {
        this.setState({
            open: value
        });
    }

    render() {
        const { searchItems, activeGames, loading, open, searching } =
            this.state;
        //  console.log(this.state);

        const filterOption = (options, { inputValue }) =>
            matchSorter(options, inputValue);

        return (
            <Box
                sx={{
                    maxWidth: 1000,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box
                    sx={{
                        maxWidth: 600,
                        display: 'flex',
                        flexDirection: 'column',
                        m: 0,
                        p: 0
                    }}
                >
                    <Autocomplete
                        value={this.state.selectedGame}
                        onChange={this.handleOnSelect}
                        options={this.state.searchItems}
                        loading={searching}
                        sx={{ p: 0, m: 1, w: '90%' }}
                        size="small"
                        style={{
                            fontFamily: 'GT Walsheim Pro',
                            fontSize: 'smaller'
                        }}
                        getOptionLabel={(option) =>
                            `${option.teamA} vs ${option.teamB}`
                        }
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.key}>
                                <OptionRow option={option} />
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={this.handleOnChangeKey}
                                value={this.state.searchValue}
                                label="Find Game"
                                inputProps={{
                                    ...params.inputProps
                                    // endAdornment: (
                                    //     <React.Fragment>
                                    //       {/* {searching ? <CircularProgress color="inherit" size={20} /> : null} */}
                                    //       {params.InputProps.endAdornment}
                                    //     </React.Fragment>
                                    // ),
                                }}
                            />
                        )}
                    />
                </Box>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Paper
                        variant="outlined"
                        sx={{ p: 1, m: 1, minHeight: 400 }}
                        square
                    >
                        <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="small"
                        >
                            Active Games
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: 500
                            }}
                        >
                            {this.state.loadingGames ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: 450,
                                        m: 0
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <StyledDataGrid
                                    rows={this.state.activeGames}
                                    columns={dataColumns}
                                    pageSize={20}
                                    rowsPerPageOptions={[20]}
                                    sx={{
                                        minWidth: 600
                                    }}
                                    disableSelectionOnClick
                                />
                            )}
                        </Box>
                    </Paper>
                </Grid>
            </Box>
        );
    }
}

function AddGames(props) {
    return <AddGamesComponent {...props} />;
}

export default withCookies(AddGames);
