import React from 'react';
import { Box, Typography, Button, Paper, CircularProgress } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { withCookies } from 'react-cookie';

import axios from '../../api/axios';

function TipRow({ tip, cookies }) {
    const [loading, setLoading] = React.useState(false);

    const handleRemove = (e) => {
        e.preventDefault();

        setLoading(true);

        const config = {
            method: 'PUT',
            url: `/api/manage/invalidate-slip/${tip['id']}`,
            headers: {
                Authorization: `Bearer ${cookies.get('token')}`
            }
        };

        axios
            .request(config)
            .then((res) => {
                alert(`Success: ${res.data['message']}`);
            })
            .catch((err) => {
                alert(`Failed: ${err.response.data['message']}`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fixture = tip["tips"][0]["fixture"];

    return (
        <Paper
            variant="outlined"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle1">
                    {fixture['home_team']} vs {fixture['away_team']}
                </Typography>
                <Typography variant="caption">
                    {fixture['league']}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <LoadingButton variant="contained" color="error" loading={loading} onClick={handleRemove}>
                    Remove
                </LoadingButton>
            </Box>
        </Paper>
    );
}

class FreeTipsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tips: [],
            loading: false
        };

        this.handleLoadTips = this.handleLoadTips.bind(this);
    }

    componentDidMount() {
        this.handleLoadTips();
    }

    handleLoadTips() {
        this.setState({
            loading: true
        });

        const config = {
            method: 'GET',
            url: '/api/slip/free',
            headers: {}
        };

        axios
            .request(config)
            .then((res) => {
                this.setState({
                    tips: []
                });

                const games = res.data['data'];
                games.map((tip) => {
                    this.setState((prev) => ({
                        tips: games
                    }));
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        const TipRows = () => {
            this.state.tips.map((tip) => (
                <TipRow tip={tip} cookies={this.props.cookies} />
            ))
        };

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: { sm: '100%', md: '100%', lg: '50%' }
                }}
            >
                {this.state.loading ? (<Box sx={{ display: 'flex', width: '100%', height: 300 }}>
                    <CircularProgress />
                </Box>) :
                    (this.state.tips.map((tip) => (
                        <TipRow tip={tip} cookies={this.props.cookies} />
                    )))}
            </Box>
        );
    }
}

const FreeTips = (props) => <FreeTipsComponent {...props} />;

export default withCookies(FreeTips);
