import React from 'react';

import {
    Avatar,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';
import { useCookies } from 'react-cookie';
import axios from '../../api/axios';
import { LoadingButton } from '@mui/lab';

const PricingForm = (props) => {
    const [cookies] = useCookies(['token']);
    // console.log(subscription);

    const [subscription, setSubscription] = React.useState(props.subscription);

    const id = subscription['id'];
    const [name, setName] = React.useState(subscription['name']);
    const [description, setDescription] = React.useState(subscription['description']);
    const [price, setPrice] = React.useState(subscription['price']);
    const [monthPrice, setMonthPrice] = React.useState(subscription['month_price']);

    const handleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        if (id === "name") {
            setName(value);
        } else if (id === "description") {
            setDescription(value);
        } else if (id === "price") {
            setPrice(value);
        } else if (id === "month_price") {
            setMonthPrice(value);
        }
    };

    const [loading, setLoading] = React.useState(false);

    const handleUpdate = () => {
        const config = {
            url: '/api/manage/update-subscription-kind',
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${cookies.token}`
            },
            data: {
                id: id,
                name: name,
                description: description,
                price: price,
                month_price: monthPrice
            }
        };

        setLoading(true);

        axios.request(config).then((res) => {
            alert(res.data["message"]);
        }).catch((error) => {
            const err = error.response["data"]
            if (err && err["message"]) {
                if (err["detail"]) {
                    alert(`${err["message"]} ${err["detail"]}`)
                } else {
                    alert(err["message"])
                }
            } else {
                alert("Error occurred. Try again.");
            }
        }).finally(() => {
            setLoading(false);
        })
    };

    const MiniGridContainer = ({ children }) => (
        <Grid container item xs={12} rowSpacing={1} columnSpacing={1} alignItems="center">
            {children}
        </Grid>
    );
    const MiniGrid = ({ title, children }) => (
        <MiniGridContainer>
            <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1">{title}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                {children}
            </Grid>
        </MiniGridContainer>
    );

    const MiniGridText = ({ title, value, disabled = true }) => (
        <MiniGrid title={title}>
            <OutlinedInput value={value} disabled={disabled} fullWidth />
        </MiniGrid>
    );

    return (
        <Card
            elevation={0}
            sx={{
                diplay: "flex",
                flexDirection: "column",
                gap: 1,
                p: 0,
                m: 0
            }}
        >
            <Typography variant="subtitle1" sx={{ m: 1 }}>
                {subscription['name']} Subscription
            </Typography>

            <Divider />

            <Grid container rowSpacing={1} columnSpacing={1} sx={{ p: 1 }}>
                <MiniGrid title="Name">
                    <OutlinedInput 
                        value={name}
                        id="name"
                        size="small"
                        onChange={handleChange}
                        fullWidth
                        />
                </MiniGrid>
                <MiniGrid title="Description">
                    <OutlinedInput 
                        value={description}
                        id="description"
                        size="small"
                        onChange={handleChange}
                        fullWidth
                        />
                </MiniGrid>
                <MiniGrid title="7 Days">
                    <OutlinedInput 
                        value={price}
                        id="price"
                        type="number"
                        size="small"
                        onChange={handleChange}
                        fullWidth
                        />
                </MiniGrid>
                <MiniGrid title="30 Days">
                    <OutlinedInput 
                        value={monthPrice}
                        id="month_price"
                        type="number"
                        size="small"
                        onChange={handleChange}
                        fullWidth
                        />
                </MiniGrid>
                <MiniGrid title="">
                    <LoadingButton loading={loading} variant="contained" onClick={handleUpdate} sx={{ width: "100%" }}>
                        Submit
                    </LoadingButton>
                </MiniGrid>
            </Grid>
        </Card>
    )
};

const Pricing = () => {
    const [cookies] = useCookies(['token']);
    const [bronze, setBronze] = React.useState(null);
    const [gold, setGold] = React.useState(null);

    const handleLoadData = () => {
        const config = {
            method: 'GET',
            url: '/api/manage/get-subscription-kind-admin',
            headers: {
                'Authorization': `Bearer ${cookies.token}`
            }
        };

        axios.request(config).then((res) => {
            const data = res['data'];
            setBronze(data['bronze']);
            setGold(data['gold']);
        }).catch((error) => {
            console.log(error.response);
        })
    };

    React.useEffect(() => {
        handleLoadData();
    }, []);

    return (
        <Box sx={{ p: 0, m: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    {gold && <PricingForm subscription={gold} />}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    { bronze && <PricingForm subscription={bronze} />}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Pricing;