export function createGameSearch(id, teamA, teamB, date, league) {
    return {
        id: parseInt(id),
        teamA: teamA,
        teamB: teamB,
        date: new Date(date),
        league: league
    };
}

export const Months = [
    '',
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
];

export const Days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

export const Markets = [
    '',
    'Home Win',
    'Away Win',
    'Draw',
    'Over 2.5',
    'Under 2.5'
];

export const CURRENCIES = {
    'ke': 'Ksh',
    'ng': '₦'
}