import { Container, styled } from '@mui/material';

export const HContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        padding: 0
    },
    [theme.breakpoints.up('md')]: {
        padding: 0
    }
}));
