import axios from 'axios';

const LOCAL = false; //process.env.NODE_ENV === "Development";

const LOCAL_URL = 'http://localhost:8000/bc';

export const BASE_URL = LOCAL ? LOCAL_URL : 'https://app.sokafans.com/bc';

export default axios.create({
    baseURL: LOCAL ? LOCAL_URL : BASE_URL
});
