import React from 'react';

import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const inputFontStyle = {
    fontWeight: '500',
    marginBottom: 5
};

export const labelFontStyle = {
    fontWeight: '500'
};

export default function PasswordInput(props) {
    const error = props.error;
    const value = props.value;
    const handleOnChange = props.onChange;
    const ariaLabel = props.ariaLabel;
    const label = props.label;

    const [showPassword, setShowPass] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPass(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl variant="outlined">
            <InputLabel htmlFor={ariaLabel}>{label}</InputLabel>
            <OutlinedInput
                id={ariaLabel}
                label="New Password"
                value={value}
                error={error}
                onChange={handleOnChange}
                inputLabelProps={{
                    style: { ...labelFontStyle, color: 'black' }
                }}
                inputProps={{ style: { ...inputFontStyle } }}
                size="small"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText error={error !== ''}>{error}</FormHelperText>
        </FormControl>
    );
}
