import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    Link,
    Modal,
    OutlinedInput,
    Paper,
    Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import axios, { BASE_URL } from '../../api/axios';
import { StyledDataGrid } from '../../components/DataListComponents';
import { modalStyle } from '../../components/customStyles';
import { LoadingButton } from '@mui/lab';

function createData(id, withdrawal, user) {
    return {
        id: id,
        wid: withdrawal.id,
        username: user.username,
        amount: withdrawal.amount,
        phone: withdrawal.phone_number,
        status: withdrawal.status,
        date: Date.parse(withdrawal.date),
        paid_at: withdrawal.paid_at
    };
}

const parseDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const dataColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 10
    },
    {
        field: 'username',
        headerName: 'Tipster',
        width: 120,
        flex: 1
    },
    {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 50,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="body1">
                Ksh. {params.row.amount.toFixed(2)}
            </Typography>
        )
    },
    {
        field: 'phone',
        headerName: 'Phone Number',
        minWidth: 70,
        flex: 1
    },
    {
        field: 'date',
        headerName: 'Date Requested',
        minWidth: 50,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="body1">
                {parseDate(new Date(params.row.date))}
            </Typography>
        )
    },
    {
        field: 'paid_at',
        headerName: 'Date Paid',
        minWidth: 50,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="body1">
                {parseDate(new Date(params.row.paid_at))}
            </Typography>
        )
    }
];

class PaidRequestsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payments: [],
            loading: false,

            token: props.cookies.get('token')
        };

        this.handleLoadData = this.handleLoadData.bind(this);
    }

    componentDidMount() {
        if (this.state.payments.length === 0) this.handleLoadData();
    }

    handleLoadData() {
        const { cookies } = this.props;
        const token = cookies.get('token');
        this.setState({
            loading: true
        });

        const options = {
            method: 'GET',
            url: `${BASE_URL}/api/manage/withdraw-request-status/W`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data['data'];
                const users = response.data['users'];
                console.log(data.length);
                this.setState({
                    payments: []
                });
                for (let i = 0; i < data.length; i++) {
                    this.setState((prev) => ({
                        payments: [
                            ...prev.payments,
                            createData(i + 1, data[i], users[i])
                        ]
                    }));
                }
            })
            .catch(function (error) {
                console.error(error);
            })
            .finally(() => {
                console.log(this.state.payments);
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        return (
            <Box
                sx={{
                    p: 0,
                    m: 0,
                    gap: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                    // maxWidth: 700s
                }}
            >
                <Paper
                    variant="outlined"
                    elevation={0}
                    sx={{
                        p: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 500
                        }}
                    >
                        {this.state.loading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: 450,
                                    m: 0
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <StyledDataGrid
                                rows={this.state.payments}
                                columns={dataColumns}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                disableSelectionOnClick
                            />
                        )}
                    </Box>
                </Paper>
            </Box>
        );
    }
}

function PaidRequests(props) {
    return <PaidRequestsComponent {...props} />;
}

export default withCookies(PaidRequests);
