import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

// noinspection JSUnusedLocalSymbols
export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['GT Walsheim Pro'].join(','),
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 500,
        fontFamily: 'GT Walsheim Pro'
    }
}));
