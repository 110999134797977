import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';

import AddGames from './AddGames';
import MySlips from './MySlips';
import Dashboard from './Dashboard';
import Pricing from './Pricing';

import { TabPanel, a11yProps } from '../GameManager';

const Subscriptions = () => {
    const [value, setValue] = React.useState(0);
    const handleTabChange = (e, newValue) => {
        e.preventDefault();
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange}>
                    <Tab label="Dashboard" {...a11yProps(0)} />
                    <Tab label="Add Tips" {...a11yProps(1)} />
                    <Tab label="My Tips" {...a11yProps(2)} />
                    <Tab label="Pricing" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel index={0} value={value}><Dashboard /></TabPanel>
            <TabPanel index={1} value={value}><AddGames /></TabPanel>
            <TabPanel index={2} value={value}><MySlips /></TabPanel>
            <TabPanel index={3} value={value}><Pricing /></TabPanel>
        </Box>
    );
};

export default Subscriptions;