import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';

import axios from '../../api/axios';
import { useCookies } from 'react-cookie';

const defaultDash = {
    active_gold: 0,
    gold_today: 0,
    active_bronze: 0,
    bronze_today: 0,
    all_time_subscriptions: 0,
    total_count: 0,
    total_amount: 0,
    total_amount_monthly: 0,
    total_amount_today: 0
};

export function DashButton(props) {
    return (
        <Grid item xs={6} sm={6} md={3} lg={3} sx={{ p: 0, m: 0 }}>
            <Button
                color={props.onClick && !props.ignore ? 'primary' : 'success'}
                size={props.onClick && !props.ignore ? 'small' : 'inherit'}
                disableElevation
                variant="outlined"
                sx={{
                    m: 0,
                    p: 0,
                    minHeight: props.onClick && !props.ignore ? 108 : 50,
                    width: '100%',
                    maxWidth: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                onClick={props.onClick}
            >
                <Typography variant="button" textAlign="center">
                    {props.label}
                </Typography>
                {props.value ? <Typography>{props.value}</Typography> : null}
            </Button>
        </Grid>
    );
}


const Dashboard = () => {
    const [cookies] = useCookies(['token']);
    const [dash, setDash] = React.useState(defaultDash);

    const handleLoadDashData = () => {
        const config = {
            method: 'GET',
            url: '/api/manage/get-subscription-stats',
            headers: {
                Authorization: `Bearer ${cookies.token}`
            }
        };

        // alert('Get Dashboard');

        axios
            .request(config)
            .then((res) => {
                const data = res.data;
                setDash(data);
                console.log(data);
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    React.useEffect(() => {
        handleLoadDashData();
    }, []);

    return (
        <Box sx={{ maxWidth: 800 }}>
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="flex-start"
                wrap="wrap"
                sx={{ p: '1px', m: 0, w: 1 }}
            >
                <DashButton
                    label="Today Bronze"
                    value={`#${dash.bronze_today}`}
                />
                <DashButton label="Today Gold" value={`#${dash.gold_today}`} />
                
                <DashButton
                    label="Active Bronze"
                    value={`#${dash.active_bronze}`}
                />
                <DashButton
                    label="Active Gold"
                    value={`#${dash.active_gold}`}
                />

                <DashButton
                    label="Today Earnings"
                    value={`$. ${dash.total_amount_today}`}
                />

                <DashButton
                    label="Monthly Earnings"
                    value={`$. ${dash.total_amount_monthly}`}
                />

                <DashButton
                    label="Total Earnings"
                    value={`$. ${dash.total_amount}`}
                />

                <DashButton
                    label="All Time Subscriptions"
                    value={`#${dash.all_time_subscriptions}`}
                />
            </Grid>
        </Box>
    );
};

export default Dashboard;
