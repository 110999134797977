import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createTheme } from '@mui/material';
import GTWalsheimProRegular from './assets/fonts/GTWalsheimPro-Regular.ttf';
import GTWalsheimProBold from './assets/fonts/GTWalsheimPro-Bold.ttf';
import GTWalsheimProMedium from './assets/fonts/GTWalsheimPro-Medium.ttf';

const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    return (
        <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
    );
});

LinkBehavior.propTypes = {
    href: PropTypes.oneOfType([
        PropTypes.shape({
            hash: PropTypes.string,
            pathname: PropTypes.string,
            search: PropTypes.string
        }),
        PropTypes.string
    ]).isRequired
};


const Typography = (fontFamily) => ({
    htmlFontSize: 16,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 600,
        fontSize: '2.375rem',
        lineHeight: 1.21
    },
    h2: {
        fontWeight: 600,
        fontSize: '1.875rem',
        lineHeight: 1.27
    },
    h3: {
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: 1.33
    },
    h4: {
        fontWeight: 600,
        fontSize: '1.25rem',
        lineHeight: 1.4
    },
    h5: {
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: 1.5
    },
    h6: {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.57
    },
    caption: {
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.66
    },
    body1: {
        fontSize: '0.875rem',
        lineHeight: 1.57
    },
    body2: {
        fontSize: '0.75rem',
        lineHeight: 1.66
    },
    subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57
    },
    subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.66
    },
    overline: {
        lineHeight: 1.66
    },
    button: {
        textTransform: 'capitalize'
    }
});

export const theme = createTheme({
    typography: Typography('GT Walsheim Pro'),
    
    components: {
        MuiCssBaseline: {
            styleOverrides: `
      @font-face {
          font-family: 'GT Walsheim Pro';
          src: url('GTWalsheimPro-Regular.eot');
          src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
              url('${GTWalsheimProRegular}') format('truetype');
          font-weight: normal;
          font-style: normal;
      }
      
      @font-face {
          font-family: 'GT Walsheim Pro';
          src: url('GTWalsheimPro-Bold.eot');
          src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
              url('${GTWalsheimProBold}') format('truetype');
          font-weight: bold;
          font-style: normal;
      }
      
      @font-face {
        font-family: 'GT Walsheim Pro';
        src: url('GTWalsheimPro-Medium.eot');
        src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
            url('${GTWalsheimProMedium}') format('truetype');
        font-weight: 500;
        font-style: normal;
      }
      `
        },

        MuiLink: {
            defaultProps: {
                component: LinkBehavior
            }
        },

        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior
            }
        },

        MuiButton: {
            defaultProps: {
                size: 'small'
            }
        }
    }
});
