import React from 'react';

import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    Typography
} from '@mui/material';

import { createGameSearch, Days, Months } from '../../../data';
import { withCookies } from 'react-cookie';
import { Autocomplete } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios, { BASE_URL } from '../../../api/axios';

import { matchSorter } from 'match-sorter';
import { StyledDataGrid } from '../../../components/DataListComponents';

export function OptionRow(props) {
    const option = props.option;

    const suffixDate = (n) => {
        if (n === 1) return 'ST';
        if (n === 2) return 'ND';
        if (n === 3) return 'RD';
        return 'TH';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                minWidth: '100%',
                h: 1
            }}
        >
            <Chip
                size="small"
                style={{ fontFamily: 'GT Walsheim Pro', fontWeight: 500 }}
                label={option.league}
                sx={{ p: 0, m: 0 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        justifyContent: 'center'
                    }}
                >
                    <Typography fontSize="small">
                        {Months[parseInt(option.date.getMonth()) + 1]}
                    </Typography>
                    <Typography fontWeight={500} fontSize="small">
                        {`${option.date.getDate()} ${suffixDate(
                            option.date.getDate()
                        )}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        justifyContent: 'center'
                    }}
                >
                    <Typography fontSize="small">{option.teamA}</Typography>
                    <Typography fontSize="small">{option.teamB}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export function createData(id, game, handleRemove) {
    const data = game.data;

    return {
        id: id,
        game_id: data['id'],
        home_team: data['home_team'],
        away_team: data['away_team'],
        date: data['game_date'],
        league: data['league'],
        result: !data['home_goals']
            ? 'Not Started'
            : `${data['home_goals']} : ${data['away_goals']}`,
        tips: game['tips'],
        handleRemove: handleRemove
    };
}

export const parseDate = (date) => {
    return `${date.getDate()} ${
        Months[date.getMonth()]
    }  ${date.getHours()}:${date.getMinutes()} `;
};

export const dataColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 10
    },
    {
        field: 'home_team',
        headerName: 'Home',
        width: 150,
        flex: 2
    },
    {
        field: 'away_team',
        headerName: 'Away',
        width: 150,
        flex: 2
    },
    {
        field: 'league',
        headerName: 'League',
        width: 70,
        flex: 1
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 50,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="caption">
                {parseDate(new Date(params.row.date))}
            </Typography>
        )
    },
    {
        field: 'action',
        headerName: 'Action',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
            <Button
                variant="contained"
                disableElevation
                color="secondary"
                size="small"
                textSizeSmall
                value={params.row.game_id}
                onClick={params.row.handleRemove}
            >
                Remove
            </Button>
        )
    }
];
