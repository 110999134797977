import React from 'react';

import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { withCookies } from 'react-cookie';
import axios, { BASE_URL } from '../../api/axios';

class GameSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingRecover: false,
            colorRecover: 'primary',

            loadingUpdateTips: false,
            colorUpdate: 'primary',

            loadingRemove: false,
            colorRemove: 'primary'
        };

        this.handleRecoverGames = this.handleRecoverGames.bind(this);
        this.handleUpdateTips = this.handleUpdateTips.bind(this);
        this.handleAddWeekly = this.handleAddWeekly.bind(this);
        this.handleRemoveGames = this.handleRemoveGames.bind(this);
    }

    handleRecoverGames() {
        this.setState({
            loadingRecover: true
        });

        const options = {
            method: 'GET',
            url: `${BASE_URL}/api/manage/restore-games`
        };

        axios
            .request(options)
            .then(() => {
                this.setState({
                    colorRecover: 'primary'
                });
            })
            .catch(() => {
                this.setState({
                    colorRecover: 'error'
                });
            })
            .finally(() => {
                this.setState({
                    loadingRecover: false
                });
            });
    }

    handleUpdateTips() {
        this.setState({
            loadingUpdateTips: true
        });

        const options = {
            method: 'GET',
            url: `${BASE_URL}/api/slip/update/all`
        };

        axios
            .request(options)
            .then(() => {
                this.setState({
                    colorUpdate: 'primary'
                });
            })
            .catch(() => {
                this.setState({
                    colorUpdate: 'error'
                });
            })
            .finally(() => {
                this.setState({
                    loadingUpdateTips: false
                });
            });
    }

    handleAddWeekly() {}

    handleRemoveGames() {
        this.setState({
            loadingRemove: true
        });

        const options = {
            method: 'GET',
            url: `${BASE_URL}/api/manage/remove-fixtures`
        };

        axios
            .request(options)
            .then(() => {
                this.setState({
                    colorRemove: 'success'
                });
            })
            .catch(() => {
                this.setState({
                    colorRemove: 'error'
                });
            })
            .finally(() => {
                this.setState({
                    loadingRemove: false
                });
            });
    }

    render() {
        return (
            <Box
                sx={{
                    maxWidth: 1000,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            alignItems: 'center'
                        }}
                    >
                        <LoadingButton
                            variant="contained"
                            disableElevation
                            loading={this.state.loadingRecover}
                            color={this.state.colorRecover}
                            onClick={this.handleRecoverGames}
                        >
                            Recover
                        </LoadingButton>
                    </Box>
                    <Typography variant="caption" component="div">
                        Recover used games lost during clean up
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            alignItems: 'center'
                        }}
                    >
                        <LoadingButton
                            variant="contained"
                            disableElevation
                            color={this.state.colorUpdate}
                            loading={this.state.loadingUpdateTips}
                            onClick={this.handleUpdateTips}
                        >
                            Update Tips
                        </LoadingButton>
                    </Box>
                    <Typography variant="caption" component="div">
                        Updates tips incase of lag from the auto updater
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            alignItems: 'center'
                        }}
                    >
                        <LoadingButton variant="contained" disableElevation>
                            Add Weekly
                        </LoadingButton>
                    </Box>
                    <Typography variant="caption" component="div">
                        Add Weekly Games
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            alignItems: 'center'
                        }}
                    >
                        <LoadingButton
                            variant="contained"
                            disableElevation
                            loading={this.state.loadingRemove}
                            color={this.state.colorRemove}
                            onClick={this.handleRemoveGames}
                        >
                            Remove Unplayed
                        </LoadingButton>
                    </Box>
                    <Typography variant="caption" component="div">
                        Removes untipped and none top games from the system
                    </Typography>
                </Box>
            </Box>
        );
    }
}

export default withCookies(GameSettings);
