import GTWalsheimProRegular from '../../assets/fonts/GTWalsheimPro-Regular.ttf';
import GTWalsheimProBold from '../../assets/fonts/GTWalsheimPro-Bold.ttf';
import GTWalsheimProMedium from '../../assets/fonts/GTWalsheimPro-Medium.ttf';

export default function CssBaseline(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                          font-family: 'GT Walsheim Pro';
                          src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
                              url('${GTWalsheimProRegular}') format('truetype');
                          font-weight: normal;
                          font-style: normal;
                          }
                          
                          @font-face {
                              font-family: 'GT Walsheim Pro';
                              src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
                                  url('${GTWalsheimProBold}') format('truetype');
                              font-weight: bold;
                              font-style: normal;
                          }
                          
                          @font-face {
                            font-family: 'GT Walsheim Pro';
                            src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
                                url('${GTWalsheimProMedium}') format('truetype');
                            font-weight: 500;
                            font-style: normal;
                          }
            `
        }
    }
}