import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';

import App from './App';
// import Dashboard from './routes/Dashboard';
import Users from './routes/Users';
import Finances from './routes/Finances';
import GameManager from './routes/GameManager';
import Settings from './routes/Settings';
import RequireAuth from './components/RequireAuth';
import Login from './routes/auth/Login';
import SlipManager from './routes/SlipManager';
import Subscriptions from './routes/Subscriptions';

import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';

import Loadable from './components/Loadable';

const Dashboard = Loadable(lazy(() => import('./routes/Dashboard')));

const root = ReactDOM.createRoot(document.getElementById('root'));

const MRoutes = [
    {
        path: '/users',
        element: <Users />
    },
    {
        path: '/finances',
        element: <Finances />
    },
    {
        path: '/games',
        element: <GameManager />
    },
    {
        path: '/settings',
        element: <Settings />
    },
    {
        path: '/tips',
        element: <SlipManager />
    },
    {
        path: '/subscriptions',
        element: <Subscriptions />
    }
];

root.render(
    <CookiesProvider>
        <ThemeCustomization>
        <BrowserRouter>
            
                <ScrollTop>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route
                                index
                                element={
                                    <RequireAuth>
                                        <Dashboard />
                                    </RequireAuth>
                                }
                            />

                            {MRoutes.map((page) => (
                                <Route
                                    path={page.path}
                                    element={
                                        <RequireAuth>
                                            {' '}
                                            {page.element}{' '}
                                        </RequireAuth>
                                    }
                                />
                            ))}
                        </Route>
                        <Route path="/login" element={<Login />} />
                    </Routes>
                </ScrollTop>
            
        </BrowserRouter>
        </ThemeCustomization>
    </CookiesProvider>
);
