import React from 'react';

import { withCookies } from 'react-cookie';
import {
    Avatar,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from '@mui/material';

import Box from '@mui/material/Box';

import { StyledDataGrid } from '../../components/DataListComponents';

import axios, { BASE_URL } from '../../api/axios';

function formatDate(date) {
    if (!date) return 'None';
    const d = new Date(date);
    return d.toLocaleDateString('en-UK');
}

export function GameDialog(props) {
    const { tips } = props;

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.tips && props.tips.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    });

    const handleClose = () => {
        props.handleClose();
    };

    const tipCount = [];

    if (tips)
        for (let i = 0; i < tips.length; i++) {
            const game_date = new Date(tips[i]['game_date']);
            var hours = (game_date - Date.now()) / 36e5;

            tipCount.push(
                <ListItem key={tips[i]['id']} disablePadding divider>
                    <ListItemText
                        secondaryTypographyProps={{
                            color: 'black'
                        }}
                        primary={`${tips[i]['home_team']} vs ${tips[i]['away_team']}`}
                        secondary={hours > 0 ? 'Not Played' : 'Past Game'}
                    />
                </ListItem>
            );
        }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Tips</DialogTitle>
            <DialogContent>
                <List sx={{ width: '100%' }}>{tipCount}</List>
            </DialogContent>
        </Dialog>
    );
}

function createData(data, id, navigate, viewTip, deleteTip, updateTip) {
    const slip = data.slip;
    const tips = data.tips;
    const user = data.user;

    console.log(tips);

    var count = 0;

    for (let i = 0; i < tips.length; i++) {
        const game_date = new Date(tips[i]['game_date']);

        const hours = (game_date - Date.now()) / 36e5;

        console.log(`${game_date} - ${Date.now()} = ${hours}`);

        count += hours < -5 ? 1 : 0;
    }

    const check = count != tips.length;

    return {
        id: id,
        slip_id: slip['id'],
        navigate: navigate,
        date: slip['date_created'],
        username: user['username'],
        rating: 0,
        count: data.tips.length,
        games: data.tips,
        price: 50,
        viewTip: viewTip,
        deleteTip: deleteTip,
        updateTip: updateTip,
        check: check ? 'ok' : 'late'
    };
}

const dataColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 5,
        flex: 1
    },
    {
        field: 'profile_pic',
        headerName: 'PC',
        maxWidth: 26,
        renderCell: (params) => (
            <Avatar
                sx={{ width: 24, height: 24 }}
                src={params.row.profile_pic}
            />
        )
    },
    {
        field: 'username',
        headerName: 'Tipster',
        minWidth: 100,
        flex: 1,
        editable: false,
        renderCell: (params) => (
            <Link
                fontWeight="bold"
                fontFamily="GT Walsheim Pro"
                fontSize="small"
                underline="hover"
                style={{ color: 'black' }}
                to={`https://sokafans.com/profile/${params.row.username}`}
            >
                {params.row.username}
            </Link>
        )
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 110,
        flex: 1,
        type: 'date',
        editable: false,
        sortable: true,
        renderCell: (params) => (
            <Typography variant="subtitle1">
                {formatDate(params.row.date)}
            </Typography>
        )
    },
    {
        field: 'count',
        headerName: 'Count',
        maxWidth: 20
    },
    {
        field: 'games',
        headerName: 'Tip',
        minWidth: 125,
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    textSizeSmall
                    value={params.row.slip_id}
                    onClick={params.row.viewTip}
                >
                    View
                </Button>

                <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    textSizeSmall
                    value={params.row.slip_id}
                    onClick={params.row.updateTip}
                    color="secondary"
                >
                    Update
                </Button>

                <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    textSizeSmall
                    value={params.row.slip_id}
                    onClick={params.row.deleteTip}
                    color="error"
                >
                    Delete
                </Button>
            </Box>
        )
    },
    {
        field: 'price',
        headerName: 'Price',
        minWidth: 75,
        flex: 1,
        type: 'number',
        editable: false,
        renderCell: (params) => `Ksh. ${params.row.price}`
    },
    {
        field: 'check',
        headerName: 'Validity',
        minWidth: 75,
        flex: 1,
        type: 'string',
        editable: false,
        renderCell: (params) => (
            <Chip
                label={params.row.check}
                color={params.row.check === 'ok' ? 'success' : 'error'}
            />
        )
    }
];

class LatestTopTipsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTips: null,
            open: false,
            tips: []
        };

        this.handleLoadActiveTips = this.handleLoadActiveTips.bind(this);
        this.handleViewTip = this.handleViewTip.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleDeleteTip = this.handleDeleteTip.bind(this);
        this.handleUpdateTip = this.handleUpdateTip.bind(this);
    }

    componentDidMount() {
        this.handleLoadActiveTips();
    }

    handleUpdateTip(e) {
        const slip_id = e.target.value;

        const { cookies } = this.props;

        var config = {
            method: 'PUT',
            url: `/api/manage/update-slip/${slip_id}`,
            headers: {}
        };

        axios
            .request(config)
            .then((response) => {
                alert(`${response.data['message']}`);
                this.handleLoadActiveTips();
            })
            .catch(function (error) {
                console.error(error);
                alert(`Error Updating tip:`);
            });
    }

    handleDeleteTip(e) {
        const slip_id = e.target.value;
        const res = window.confirm(
            `Are you sure you want to delete tip ${slip_id}`
        );

        if (!res) {
            return;
        }

        const { cookies } = this.props;

        const token = cookies.get('token');

        var options = {
            method: 'DELETE',
            url: `/api/manage/remove-slip/${slip_id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios
            .request(options)
            .then((response) => {
                this.handleLoadActiveTips();
            })
            .catch(function (error) {
                console.error(error);
                alert('Error deleting tip');
            });
    }

    handleViewTip(e) {
        const slip_id = e.target.value;
        let tips = null;
        if (this.state.activeTips)
            tips = this.state.activeTips.find(
                (tip) => tip.slip_id === parseInt(slip_id)
            );

        if (!tips) return;

        console.log('Tip View');
        console.log(tips);

        this.setState({
            open: true,
            tips: tips.games
        });
    }

    handleCloseModal() {
        this.setState({
            open: false,
            tips: null
        });
    }

    handleLoadActiveTips() {
        const { navigate } = this.props;

        const options = {
            method: 'GET',
            url: '/api/home/active'
        };

        const get = (_list) => {
            if (_list) return _list;
            return [];
        };

        this.setState({
            activeTips: null
        });

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                const slips = data.slips;
                let cnt = 1;

                slips.map((slip) => {
                    this.setState((prevState) => ({
                        activeTips: [
                            ...get(prevState.activeTips),
                            createData(
                                slip,
                                cnt++,
                                navigate,
                                this.handleViewTip,
                                this.handleDeleteTip,
                                this.handleUpdateTip
                            )
                        ]
                    }));
                    return null;
                });
            })
            .catch(function (error) {
                console.log('Error', error.response.data);
            });
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 500
                }}
            >
                <GameDialog
                    openDialoag={this.state.open}
                    handleClose={this.handleCloseModal}
                    tips={this.state.tips}
                />
                {!this.state.activeTips ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: 450,
                            m: 0
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <StyledDataGrid
                        rows={this.state.activeTips}
                        columns={dataColumns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                    />
                )}
            </Box>
        );
    }
}

function LatestTopTips(props) {
    return <LatestTopTipsComponent {...props} />;
}

export default withCookies(LatestTopTips);
