import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Container,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';

import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import axios from '../../api/axios';
import Divider from '@mui/material/Divider';

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; string && i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function PageHeader(props) {
    const hideDescription = props.description === null;

    const sx = Object.assign({}, props.sx, {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    });

    return (
        <Box>
            <Container>
                <Box sx={sx}>
                    <Typography variant="h6" color="black" fontWeight={500}>
                        {props.title}
                    </Typography>
                    {hideDescription ? null : (
                        <Typography sx={{ mt: 0 }} variant="caption">
                            {props.description}
                        </Typography>
                    )}
                </Box>
            </Container>
            {!props.disableDivider ? <Divider /> : null}
        </Box>
    );
}

export function stringAvatarSx({ name, sx }) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            ...sx
        },
        children: `${name.split(' ')[0][0]}${
            name.split(' ').length > 1 ? name.split(' ')[1][0] : ''
        }`
    };
}

export function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${name.split(' ')[0][0]}${
            name.split(' ').length > 1 ? name.split(' ')[1][0] : ''
        }`
    };
}

class ActiveTipComponent extends React.Component {
    constructor(props) {
        super(props);

        // console.log(props);

        const { slip, jackpot, cookies, type, markets } = this.props;
        console.log(type);

        this.state = {
            slip: slip,
            tips: (slip && slip.tips) || [],
            user: (slip && slip.user) || [],
            accumulator: 0,
            company: '',
            type: type,
            meta: {},
            rating: 0,
            markets: markets || [],
            jackpot: jackpot ? jackpot : {}
        };

        this.handleBuyTip = this.handleBuyTip.bind(this);
        this.handleClickChip = this.handleClickChip.bind(this);
        this.handlePublishUnpublishTip =
            this.handlePublishUnpublishTip.bind(this);
        this.handleGetMarket = this.handleGetMarket.bind(this);
        // console.log(slip);
    }

    handleGetMarket(id) {
        const { markets } = this.state;
        for (let i = 0; i < markets.length; i++) {
            if (markets[i]['id'] === id) {
                return markets[i]['value'];
            }
        }
        return "";
    }

    handleBuyTip() {
        this.props.navigate(`/buy_tip/${this.state.slip['id']}`);
    }

    handleClickChip(user) {
        const { navigate } = this.props;
        navigate(`/profile/${user}`);
    }

    handlePublishUnpublishTip(e) {
        e.preventDefault();
        const url = `/api/manage/update-jackpot-publish-status/${
            this.state.jackpot['id']
        }/${this.state.slip['published'] ? 0 : 1}`;
        console.log(url);

        const config = {
            method: 'PUT',
            url: url,
            headers: {
                Authorization: `Bearer ${this.props.cookies.get('token')}`
            }
        };

        axios
            .request(config)
            .then((response) => {
                alert('Success');
            })
            .catch((error) => {
                alert('Failed');
            });
    }

    render() {
        const IconChip = ({ username }) => {
            return (
                <Link
                    to={`/profile/${username}`}
                    underline="hover"
                    color="black"
                >
                    <Chip
                        size="small"
                        style={{
                            cursor: 'pointer'
                        }}
                        sx={{
                            '& .MuiChip-label': {
                                fontFamily: 'GT Walsheim Pro',
                                fontWeight: '500',
                                fontSize: 'small'
                            }
                        }}
                        avatar={
                            <Avatar
                                style={{ color: 'white', fontWeight: 'bold' }}
                                {...stringAvatar(username)}
                            />
                        }
                        label={username}
                    />
                </Link>
            );
        };

        const getYield = (num) => {
            const _val = Math.round((num + Number.EPSILON) * 100) / 100;
            const sign = _val >= 0 ? '+' : '';
            return `${sign}${_val}`;
        };

        try {
            if (!this.state.slip) {
                return null;
            }

            const myDate = (_date) => {
                const date = new Date(Date.parse(_date));
                // noinspection JSCheckFunctionSignatures
                return new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'medium'
                }).format(date);
            };

            const tipCount = [];
            if (this.state.slip)
                for (let i = 0; i < this.state.tips.length; i++) {
                    const game_date = new Date(this.state.tips[i]['game_date']);
                    const hours = (game_date - Date.now()) / 36e5;


                    const result = this.handleGetMarket(this.state.tips[i]['market_type']);

                    var label = '';
                    if (hours > 0) {
                        label = 'Not Started';
                    } else if (hours > -3) {
                        label = 'In play';
                    } else {
                        label = 'Played';
                    }

                    // label = this.props.buy ? label : null;

                    tipCount.push(
                        <ListItem key={i} disablePadding divider>
                            <ListItemText
                                secondaryTypographyProps={{
                                    color: 'black'
                                }}
                                primaryTypographyProps={{
                                    color: 'black',
                                    fontSize: 'smaller'
                                }}
                                primary={`${this.state.tips[i]['home_team']} vs ${this.state.tips[i]['away_team']} | ${this.state.tips[i]['league']}`}
                                secondary={result}
                            />
                        </ListItem>
                    );
                }

            return (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{
                        p: 1,
                        '@media screen and (max-width: 600px)': {
                            width: '100%'
                        }
                    }}
                >
                    <Card
                        elevation={0}
                        variant="outlined"
                        sx={{
                            display: 'inline-block',
                            m: 0,
                            width: '100%'
                        }}
                    >
                        <CardContent sx={{ maxWidth: '100%', minHeight: 220 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    p: 0
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        p: 0,
                                        m: 0,
                                        gap: 1,
                                        alignItems: 'center'
                                    }}
                                >
                                    <IconChip
                                        username={
                                            this.state.type === 'B'
                                                ? 'Bronze'
                                                : 'Gold'
                                        }
                                    />

                                    <Chip
                                        size="small"
                                        label={
                                            this.state.slip
                                                ? myDate(
                                                      this.state.slip[
                                                          'date_created'
                                                      ]
                                                  )
                                                : null
                                        }
                                        sx={{
                                            '& .MuiChip-label': {
                                                fontFamily: 'GT Walsheim Pro',
                                                fontWeight: '500',
                                                fontSize: 'small'
                                            }
                                        }}
                                    />
                                </Box>

                                <List sx={{ width: '100%' }}>{tipCount}</List>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 1,
                                    width: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 1
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color={
                                            this.state.slip &&
                                            this.state.slip['published']
                                                ? 'error'
                                                : 'primary'
                                        }
                                        disableElevation
                                        onClick={this.handlePublishUnpublishTip}
                                    >
                                        {this.state.slip &&
                                        this.state.slip['published']
                                            ? 'Unpublish'
                                            : 'Publish'}
                                    </Button>
                                </Box>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
            );
        } catch (err) {
            console.log(err);
            return <Box>None</Box>;
        }
    }
}

export function ActiveTip(props) {
    const navigate = useNavigate();
    return (
        <ActiveTipComponent
           {...props}
        />
    );
}

class MyGamesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jackpot_slips: [],
            markets: []
        };

        this.handleLoadJackpotSlips = this.handleLoadJackpotSlips.bind(this);
        this.handleLoadMarkets = this.handleLoadMarkets.bind(this);
    }

    componentDidMount() {
        this.handleLoadJackpotSlips();
        this.handleLoadMarkets();
    }

    handleLoadMarkets() {
        const options = {
            method: 'GET',
            url: '/api/slip/markets'
        };

        axios
            .request(options)
            .then((response) => {
                // // console.log(response.data);
                const markets = response.data['markets'];
                this.setState({
                    markets: markets
                });
            })
            .catch((error) => {
                // console.log("Error");
                if (error) console.log(error.response);
            });
    }

    handleLoadJackpotSlips() {
        let config = {
            method: 'GET',
            url: '/api/manage/get-subscription-all',
            headers: {
                Authorization: `Bearer ${this.props.cookies.get('token')}`
            }
        };

        axios
            .request(config)
            .then((res) => {
                const data = res.data;
                console.log(data);

                const slips = data['data'];
                console.log(slips);
                const datas = slips.sort((x, y) => {
                    // console.log(x["slip"]["date_created"]);
                    // console.log(y["slip"]["date_created"]);
                    return -(x['id'] - y['id']);
                });

                console.log(datas);

                this.setState({
                    jackpot_slips: datas
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        return (
            <Box sx={{ p: 0, m: 0 }}>
                <Paper
                    square
                    elevation={1}
                    sx={{
                        py: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <PageHeader
                        sx={{ pl: 0 }}
                        title="My Premium Tips"
                        description="View Tips"
                    />
                    <Box
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <Grid
                            container
                            spacing={0}
                            sx={{
                                m: 0,
                                p: 0,
                                maxWidth: '100%'
                            }}
                        >
                            {this.state.jackpot_slips.map((val) => (
                                <ActiveTip
                                    type={val.type}
                                    slip={val.slip}
                                    jackpot={val}
                                    cookies={this.props.cookies}
                                    markets={this.state.markets}
                                />
                            ))}
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

function MyGames(props) {
    return <MyGamesComponent {...props} />;
}

export default withCookies(MyGames);
