import React from 'react';
import { withCookies } from 'react-cookie';
import { CircularProgress, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import axios, { BASE_URL } from '../../api/axios';
import { StyledDataGrid } from '../../components/DataListComponents';
import { Months } from '../../data';

function createData(id, game) {
    const data = game.data;

    return {
        id: id,
        game_id: data['id'],
        home_team: data['home_team'],
        away_team: data['away_team'],
        date: data['game_date'],
        league: data['league'],
        result: !data['home_goals']
            ? 'Not Started'
            : `${data['home_goals']} : ${data['away_goals']}`,
        tips: game['tips']
    };
}

const parseDate = (date) => {
    return `${date.getDate()} ${
        Months[date.getMonth()]
    }  ${date.getHours()}:${date.getMinutes()} `;
};

const dataColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 10
    },
    {
        field: 'home_team',
        headerName: 'Home',
        width: 150,
        flex: 2
    },
    {
        field: 'away_team',
        headerName: 'Away',
        width: 150,
        flex: 2
    },
    {
        field: 'league',
        headerName: 'League',
        width: 70,
        flex: 1
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 50,
        flex: 1,
        renderCell: (params) => (
            <Typography variant="caption">
                {parseDate(new Date(params.row.date))}
            </Typography>
        )
    }
];

class PastGamesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeGames: [],
            loadingGames: false
        };

        this.handleLoadTopGames = this.handleLoadTopGames.bind(this);
    }

    componentDidMount() {
        this.handleLoadTopGames();
    }

    handleLoadTopGames() {
        console.log('Loading top games');
        this.setState({
            loadingGames: true
        });

        const options = {
            method: 'GET',
            url: `${BASE_URL}/api/manage/all-top-games`
        };

        axios
            .request(options)
            .then((respone) => {
                const { games } = respone.data;
                this.setState({ activeGames: [] });
                for (let i = 0; i < games.length; i++) {
                    this.setState((prev) => ({
                        activeGames: [
                            ...prev.activeGames,
                            createData(i + 1, games[i])
                        ]
                    }));
                }
            })
            .catch(function (error) {
                console.error(error);
            })
            .finally(() => {
                this.setState({
                    loadingGames: false
                });
            });
    }

    render() {
        return (
            <Box
                sx={{
                    p: 0,
                    m: 0,
                    gap: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                    // maxWidth: 700s
                }}
            >
                <Paper
                    variant="outlined"
                    elevation={0}
                    sx={{
                        p: 1,
                        mx: 0,
                        my: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 500
                        }}
                    >
                        {this.state.loadingGames ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: 450,
                                    m: 0
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <StyledDataGrid
                                rows={this.state.activeGames}
                                columns={dataColumns}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                sx={{
                                    minWidth: 600
                                }}
                                disableSelectionOnClick
                            />
                        )}
                    </Box>
                </Paper>
            </Box>
        );
    }
}

function PastGames(props) {
    return <PastGamesComponent {...props} />;
}

export default withCookies(PastGames);
