import React from 'react';
import MiniDrawer from './components/MiniDrawer';
import { Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';

import { useTheme } from '@mui/material/styles';

export default function App(props) {
    const theme = useTheme();

    return (
        <MiniDrawer>
            <Box
                component="main"
                sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}
            >
                <Outlet />
            </Box>
        </MiniDrawer>
    );
}
