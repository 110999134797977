import React from 'react';

import Box from '@mui/material/Box';
import {
    Autocomplete,
    Chip,
    Paper,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Button,
    IconButton,
    Alert,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    FormHelperText
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import AlertTitle from '@mui/material/AlertTitle';

import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { withCookies } from 'react-cookie';

import { createGameSearch, Months } from '../../data';
import axios from '../../api/axios';
import { HContainer } from '../../components/MContainer';

function OptionRow(props) {
    const option = props.option;

    const suffixDate = (n) => {
        if (n === 1) return 'ST';
        if (n === 2) return 'ND';
        if (n === 3) return 'RD';
        return 'TH';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                minWidth: '100%',
                h: 1
            }}
        >
            <Chip
                size="small"
                style={{ fontFamily: 'GT Walsheim Pro', fontWeight: 500 }}
                label={option.league}
                sx={{ p: 0, m: 0 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        justifyContent: 'center'
                    }}
                >
                    <Typography fontSize="small">
                        {Months[parseInt(option.date.getMonth()) + 1]}
                    </Typography>
                    <Typography fontWeight={500} fontSize="small">
                        {`${option.date.getDate()} ${suffixDate(
                            option.date.getDate()
                        )}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        justifyContent: 'center'
                    }}
                >
                    <Typography fontSize="small">{option.teamA}</Typography>
                    <Typography fontSize="small">{option.teamB}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

// noinspection JSValidateTypes
class AddTipsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navigator: this.props.navigate,
            searchValue: null,
            tips: [],
            tipId: null,
            searchItems: [],

            selectedGame: null,
            selectedMarket: null,

            marketTypes: [],

            error: null,
            alertType: 'error',

            searching: null,

            amount: 50,
            amountError: '',

            currentOdds: {},

            viewCorrectScore: false,

            publishing: false,

            accumulator: 1.0
        };

        this.handleMarketChange = this.handleMarketChange.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleItemDelete = this.handleItemDelete.bind(this);
        this.handleOnListClear = this.handleOnListClear.bind(this);
        this.handleOnListPublish = this.handleOnListPublish.bind(this);

        this.handleOnChangeKey = this.handleOnChangeKey.bind(this);
        this.handleChangeAmount = this.handleChangeAmount.bind(this);
        this.getOdd = this.getOdd.bind(this);
        this.getOddValue = this.getOddValue.bind(this);

        this.handleViewCorrectScore = this.handleViewCorrectScore.bind(this);
    }

    componentDidMount() {
        this.handleLoadMarkets();
        // this.handleLoadGames();
    }

    handleChangeAmount(e) {
        const amount = e.target.value;

        function isNumeric(str) {
            if (typeof str != 'string') return false; // we only process strings!
            return (
                !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str))
            ); // ...and ensure strings of whitespace fail
        }

        if (amount === '') {
            this.setState({
                amount: '',
                amountError: "Can't be empty"
            });
            return;
        }

        if (!isNumeric(amount)) {
            this.setState({
                amountError: 'Numbers only'
            });
            return;
        }

        if (amount && amount > 1000) {
            this.setState({
                amountError: 'Max Amount: 1000'
            });
            return;
        }

        this.setState({
            amount: amount,
            amountError: ''
        });
    }

    handleOnChangeKey(e) {
        const pattern = e.target.value;
        this.setState({
            searchValue: pattern
        });

        console.log(pattern);
        if (!pattern) return;
        console.log('Searching');

        this.setState({
            searching: true
        });

        const options = {
            method: 'POST',
            url: `/api/manage/search-games`,
            data: {
                pattern: pattern
            }
        };

        axios
            .request(options)
            .then((response) => {
                const { games } = response.data;
                const g_data = [];
                console.log(games.length);
                for (let i = 0; i < Math.min(20, games.length); i++) {
                    const _game = games[i];
                    const game = createGameSearch(
                        _game['id'],
                        _game['home_team'],
                        _game['away_team'],
                        _game['game_date'],
                        _game['league']
                    );
                    g_data.push(game);
                }
                console.log(g_data);
                this.setState({
                    searchItems: g_data
                });
            })
            .catch(function (error) {
                console.error(error);
            })
            .finally(() => {
                this.setState({
                    searching: true
                });
            });
    }

    handleLoadMarkets() {
        const options = {
            method: 'GET',
            url: '/api/slip/markets'
        };

        axios
            .request(options)
            .then((response) => {
                // // console.log(response.data);
                const markets = response.data['markets'];
                this.setState({
                    marketTypes: markets
                });
            })
            .catch((error) => {
                // console.log("Error");
                if (error) console.log(error.response);
            });
    }

    handleMarketChange(e) {
        const marketId = e.target.value;
        if (this.state.error) {
            this.setState({
                error: null,
                alertType: 'error'
            });
        }

        const getMarket = (marketId) => {
            for (let i = 0; i < this.state.marketTypes.length; i++) {
                const _market = this.state.marketTypes[i];
                if (_market['id'] === marketId) {
                    return _market['value'];
                }
            }
            return 'unknown';
        };

        if (this.state.selectedGame) {
            if (this.state.tips.length >= 5) {
                this.setState({
                    error: 'Maximum number of 5 games reached'
                });
                return;
            }
            if (
                this.state.tips.filter(
                    (tip) => tip.game.id === this.state.selectedGame.id
                ).length > 0
            ) {
                this.setState({
                    error: 'Game already in slip'
                });
                return;
            }
            this.setState((prev) => ({
                tips: [
                    ...prev.tips,
                    {
                        game: this.state.selectedGame,
                        market: parseInt(marketId),
                        odds: this.getOddValue(getMarket(parseInt(marketId)))
                    }
                ],
                selectedGame: null
            }));
        }
    }

    handleOnSelect(e, newValue) {
        if (!newValue) return;
        this.setState({
            selectedGame: newValue
        });

        const options = {
            method: 'GET',
            url: `/api/fixtures/search/fixture/odd/${newValue.id}`
        };

        axios
            .request(options)
            .then((response) => {
                const data = response.data;
                console.log(data);
                this.setState({
                    currentOdds: data['result']
                });
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    handleItemDelete(id) {
        // console.log(`Clear Game ${id}`);
        this.setState((prev) => ({
            tips: prev.tips.filter(function (tip) {
                return tip.game.id !== id;
            })
        }));
    }

    handleOnListClear() {
        this.setState({
            tips: [],
            error: null,
            alertType: 'error'
        });
    }

    handleOnListPublish() {
        var accumulator = 1.0;

        const mTips = [];
        var err = false;
        this.setState({
            publishing: true
        });

        for (let i = 0; i < this.state.tips.length; i++) {
            const tip = this.state.tips[i];
            accumulator *= tip.odds;
            mTips.push({
                game: tip.game.id,
                market: tip.market
            });
        }

        accumulator = parseInt((accumulator * 100).toString()) / 100.0;

        if (false && (mTips.length < 3 || mTips.length > 5)) {
            this.setState({ error: "Publish at least '3' tip" });
            err = true;
        } else if (accumulator < 1 || accumulator > 100.0) {
            this.setState({
                error: 'Total odds must be within 1.0 and 100.00 . A minimum of 1.00 and a maximum of 100.0'
            });
            err = true;
        } else if (this.state.amount > 1000 || this.state.amount < 50) {
            this.setState({ error: 'Price range Ksh. [50-1000]' });
            err = true;
        }

        if (err) {
            this.setState({
                publishing: false
            });
            return;
        }

        // // console.log(mTips);
        const { cookies } = this.props;

        const options = {
            method: 'POST',
            url: '/api/slip/create-free',
            headers: {
                Authorization: `Bearer ${cookies.get('token')}`,
                'Content-Type': 'application/json'
            },
            data: {
                published: true,
                tips: mTips,
                tip_id: this.state.tipId,
                amount: this.state.amount
            }
        };

        const getGame = (gameId) => {
            for (let i = 0; i < this.state.tips.length; i++) {
                const tip = this.state.tips[i];
                if (tip.game.id === parseInt(gameId)) {
                    return `${tip.game.teamA} vs ${tip.game.teamB}`;
                }
            }
            return null;
        };

        axios
            .request(options)
            .then((response) => {
                if (response) this.handleOnListClear();
                this.setState({
                    error: 'Successfully Published Tips',
                    alertType: 'success'
                });
            })
            .catch((error) => {
                const res = error.response.data;
                // console.log(res);
                if (res['game']) {
                    this.setState({
                        error: `${res['error']}. (${getGame(res['game'])})`
                    });
                }
                if (res['error']) {
                    this.setState({
                        error: res['error']
                    });
                } else {
                    this.setState({
                        error: 'Error Saving tips'
                    });
                }
            })
            .finally(() => {
                this.setState({
                    publishing: false
                });
            });
    }

    getOdd(market) {
        return `[ ${this.getOddValue(market)} ]`;
    }

    getOddValue(market) {
        const { currentOdds } = this.state;

        let _odd = -1;

        const _name = market.toUpperCase();

        if (_name === 'HOME WIN') _odd = currentOdds['home_win'];
        if (_name === 'AWAY WIN') _odd = currentOdds['away_win'];
        if (_name === 'DRAW') _odd = currentOdds['game_draw'];

        if (_name === 'OVER 2.5') _odd = currentOdds['over_2_5'];
        if (_name === 'UNDER 2.5') _odd = currentOdds['under_2_5'];
        if (_name === 'OVER 1.5') _odd = currentOdds['over_1_5'];
        if (_name === 'UNDER 1.5') _odd = currentOdds['under_1_5'];

        if (_name === 'NG') _odd = currentOdds['both_ng'];
        if (_name === 'GG') _odd = currentOdds['both_gg'];

        if (_name === '1X') _odd = currentOdds['double_1x'];
        if (_name === '12') _odd = currentOdds['double_12'];
        if (_name === 'X2') _odd = currentOdds['double_x2'];

        if (_name === '1/1') _odd = currentOdds['hft_11'];
        if (_name === '1/X') _odd = currentOdds['hft_1x'];
        if (_name === '1/2') _odd = currentOdds['hft_12'];

        if (_name === 'X/1') _odd = currentOdds['hft_x1'];
        if (_name === 'X/X') _odd = currentOdds['hft_xx'];
        if (_name === 'X/2') _odd = currentOdds['hft_x2'];

        if (_name === '2/1') _odd = currentOdds['hft_21'];
        if (_name === '2/X') _odd = currentOdds['hft_2x'];
        if (_name === '2/2') _odd = currentOdds['hft_22'];

        for (let h_score = 0; _odd === -1 && h_score <= 6; h_score++) {
            for (let a_score = 0; a_score <= 6 - h_score; a_score++) {
                if (_name === `${h_score}-${a_score}`) {
                    const odd_val = `cs_${h_score}_${a_score}`;
                    _odd = currentOdds[odd_val];
                    console.log(`${odd_val}-${_odd}`);
                    break;
                }
            }
        }

        return _odd ? _odd : 1;
    }

    handleViewCorrectScore() {
        this.setState({
            viewCorrectScore: !this.state.viewCorrectScore
        });
    }

    render() {
        const { tips, viewCorrectScore, marketTypes } = this.state;
        let tipList = [];

        let accumulator = 1;

        const getMarket = (marketId) => {
            for (let i = 0; i < this.state.marketTypes.length; i++) {
                const _market = this.state.marketTypes[i];
                if (_market['id'] === marketId) {
                    return _market['value'];
                }
            }
            return 'unknown';
        };

        const deleteItem = (idx) => {
            this.handleItemDelete(idx);
        };

        const correctScoreView = () => {
            return marketTypes.map(
                (market) =>
                    market['type'] === 'CS' && (
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disableElevation
                            sx={{
                                width: 1,
                                flexWidth: 1,
                                maxWidth: 'calc(33% - 4px)'
                            }}
                            value={market['id']}
                            onClick={this.handleMarketChange}
                        >
                            {market['value'] +
                                ' ' +
                                this.getOdd(market['value'])}
                        </Button>
                    )
            );
        };

        tips.map((tip) => {
            accumulator *= tip.odds;

            tipList.push(
                <ListItem
                    divider
                    disablePadding
                    key={tip.game.id}
                    secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => deleteItem(tip.game.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                >
                    <ListItemText
                        primary={`${tip.game.teamA} vs ${tip.game.teamB}`}
                        secondary={
                            getMarket(parseInt(tip.market)) +
                            ' ' +
                            `[ ${tip.odds} ]`
                        }
                    />
                </ListItem>
            );
            return null;
        });

        accumulator = parseInt((accumulator * 100).toString()) / 100.0;

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* <BreadCrumbView /> */}

                <Box sx={{ mx: 1 }}>
                    {/* <HContainer> */}
                    <Paper
                        square
                        elevation={1}
                        sx={{
                            p: 1,
                            mx: 0,
                            my: 1,
                            width: '100%',
                            height: '100%',
                            borderRadius: 1
                        }}
                    >
                        {/* <PageHeader
                                sx={{ pl: 2 }}
                                title="Add Tips"
                                description="Find teams to bet on"
                            /> */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    m: 0,
                                    p: 0
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        flexGrow: 1,
                                        width: '100%'
                                    }}
                                >
                                    <Autocomplete
                                        value={this.state.selectedGame}
                                        onChange={this.handleOnSelect}
                                        options={this.state.searchItems}
                                        sx={{
                                            p: 0,
                                            m: 1,
                                            width: { sm: '100%', lg: '50%' }
                                        }}
                                        disablePortal={true}
                                        loadingText="Searching"
                                        noOptionsText="Can't find games. Retype or check your spelling"
                                        size="small"
                                        style={{
                                            fontFamily: 'GT Walsheim Pro',
                                            fontSize: 'smaller'
                                        }}
                                        filterOptions={(x) => x}
                                        getOptionLabel={(option) =>
                                            `${option.teamA} vs ${option.teamB}`
                                        }
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                                key={option.key}
                                            >
                                                <OptionRow option={option} />
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Find Game"
                                                onChange={
                                                    this.handleOnChangeKey
                                                }
                                                value={this.state.searchValue}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    width: '100%',
                                                    'aria-autocomplete':
                                                        'new-password'
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ mx: 1, px: 1 }}>
                                    <Typography variant="caption">
                                        Type the name of the team to search. If
                                        it's stuck in loading try typing again.
                                    </Typography>
                                </Box>
                                {/* <Alert
                                        sx={{ mx: 1, py: 0 }}
                                        severity={this.state.alertType}
                                        style={{
                                            display: this.state.error
                                                ? 'inherit'
                                                : 'none'
                                        }}
                                    >
                                        {this.state.error}
                                    </Alert> */}
                            </Box>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Paper
                                        variant="outlined"
                                        sx={{ p: 1, m: 1, minHeight: 400 }}
                                        square
                                    >
                                        <Typography
                                            variant="h6"
                                            fontWeight={500}
                                            fontSize="small"
                                        >
                                            Markets
                                        </Typography>
                                        <Divider />
                                        <Box sx={{ mt: 1 }}>
                                            <Box sx={{ pb: 1, m: 0 }}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={500}
                                                    fontSize="small"
                                                >
                                                    1X2 (Winning Team)
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        gap: 1,
                                                        maxWidth: 1
                                                    }}
                                                >
                                                    {this.state.marketTypes.map(
                                                        (market) =>
                                                            market['type'] !==
                                                            '1X2' ? null : (
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disableElevation
                                                                    sx={{
                                                                        width: 1,
                                                                        flexWidth: 1,
                                                                        maxWidth:
                                                                            'calc(33% - 4px)'
                                                                    }}
                                                                    value={
                                                                        market[
                                                                            'id'
                                                                        ]
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .handleMarketChange
                                                                    }
                                                                >
                                                                    {market[
                                                                        'value'
                                                                    ] +
                                                                        ' ' +
                                                                        this.getOdd(
                                                                            market[
                                                                                'value'
                                                                            ]
                                                                        )}
                                                                </Button>
                                                            )
                                                    )}
                                                </Box>
                                            </Box>
                                            <Divider />

                                            <Box sx={{ mt: 1, pb: 1, m: 0 }}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={500}
                                                    fontSize="small"
                                                >
                                                    Over Under (Total Goals)
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        gap: 1,
                                                        maxWidth: 1
                                                    }}
                                                >
                                                    {this.state.marketTypes.map(
                                                        (market) =>
                                                            market['type'] !==
                                                            'OU' ? null : (
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disableElevation
                                                                    sx={{
                                                                        width: 1,
                                                                        flexWidth: 1,
                                                                        maxWidth:
                                                                            'calc(50% - 4px)'
                                                                    }}
                                                                    value={
                                                                        market[
                                                                            'id'
                                                                        ]
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .handleMarketChange
                                                                    }
                                                                >
                                                                    {market[
                                                                        'value'
                                                                    ] +
                                                                        ' ' +
                                                                        this.getOdd(
                                                                            market[
                                                                                'value'
                                                                            ]
                                                                        )}
                                                                </Button>
                                                            )
                                                    )}
                                                </Box>
                                            </Box>
                                            <Divider />

                                            <Box sx={{ mt: 1, pb: 1, m: 0 }}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={500}
                                                    fontSize="small"
                                                >
                                                    Both Teams (Score)
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        gap: 1,
                                                        maxWidth: 1
                                                    }}
                                                >
                                                    {this.state.marketTypes.map(
                                                        (market) =>
                                                            market['type'] !==
                                                            'BT' ? null : (
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disableElevation
                                                                    sx={{
                                                                        width: 1,
                                                                        flexWidth: 1,
                                                                        maxWidth:
                                                                            'calc(50% - 4px)'
                                                                    }}
                                                                    value={
                                                                        market[
                                                                            'id'
                                                                        ]
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .handleMarketChange
                                                                    }
                                                                >
                                                                    {market[
                                                                        'value'
                                                                    ] +
                                                                        ' ' +
                                                                        this.getOdd(
                                                                            market[
                                                                                'value'
                                                                            ]
                                                                        )}
                                                                </Button>
                                                            )
                                                    )}
                                                </Box>
                                            </Box>
                                            <Divider />

                                            <Box sx={{ mt: 1, pb: 1, m: 0 }}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={500}
                                                    fontSize="small"
                                                >
                                                    Double Chance
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        gap: 1,
                                                        maxWidth: 1
                                                    }}
                                                >
                                                    {this.state.marketTypes.map(
                                                        (market) =>
                                                            market['type'] !==
                                                            'DC' ? null : (
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disableElevation
                                                                    sx={{
                                                                        width: 1,
                                                                        flexWidth: 1,
                                                                        maxWidth:
                                                                            'calc(33% - 4px)'
                                                                    }}
                                                                    value={
                                                                        market[
                                                                            'id'
                                                                        ]
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .handleMarketChange
                                                                    }
                                                                >
                                                                    {market[
                                                                        'value'
                                                                    ] +
                                                                        ' ' +
                                                                        this.getOdd(
                                                                            market[
                                                                                'value'
                                                                            ]
                                                                        )}
                                                                </Button>
                                                            )
                                                    )}
                                                </Box>
                                            </Box>
                                            <Divider />

                                            <Box sx={{ mt: 1, pb: 1, m: 0 }}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={500}
                                                    fontSize="small"
                                                >
                                                    Halftime / Fulltime
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        gap: 1,
                                                        maxWidth: 1
                                                    }}
                                                >
                                                    {this.state.marketTypes.map(
                                                        (market) =>
                                                            market['type'] !==
                                                            'HFT' ? null : (
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disableElevation
                                                                    sx={{
                                                                        width: 1,
                                                                        flexWidth: 1,
                                                                        maxWidth:
                                                                            'calc(33% - 4px)'
                                                                    }}
                                                                    value={
                                                                        market[
                                                                            'id'
                                                                        ]
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .handleMarketChange
                                                                    }
                                                                >
                                                                    {market[
                                                                        'value'
                                                                    ] +
                                                                        ' ' +
                                                                        this.getOdd(
                                                                            market[
                                                                                'value'
                                                                            ]
                                                                        )}
                                                                </Button>
                                                            )
                                                    )}
                                                </Box>
                                            </Box>

                                            <Divider />

                                            <Box sx={{ mt: 1, pb: 1, m: 0 }}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={500}
                                                    fontSize="small"
                                                >
                                                    Correct Score
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        gap: 1,
                                                        maxWidth: 1
                                                    }}
                                                >
                                                    {viewCorrectScore &&
                                                        correctScoreView()}
                                                </Box>

                                                <Button
                                                    disableElevation
                                                    variant="text"
                                                    onClick={
                                                        this
                                                            .handleViewCorrectScore
                                                    }
                                                >
                                                    {viewCorrectScore
                                                        ? 'Close'
                                                        : 'View'}
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Paper
                                        variant="outlined"
                                        sx={{ p: 1, m: 1, minHeight: 400 }}
                                        square
                                    >
                                        <Box
                                            display={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <Box
                                                display={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: 1
                                                }}
                                            >
                                                <Box
                                                    display={{
                                                        p: 2,
                                                        m: 1,
                                                        gap: 1,
                                                        display: 'flex',
                                                        flexDirection: 'row'
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        fontWeight={500}
                                                        fontSize="small"
                                                    >
                                                        Tips
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    display={{
                                                        p: 2,
                                                        m: 1,
                                                        gap: 1,
                                                        display: 'flex',
                                                        flexDirection: 'row'
                                                    }}
                                                ></Box>
                                            </Box>

                                            <Divider />

                                            <Alert
                                                sx={{ m: 1, py: 0 }}
                                                severity={this.state.alertType}
                                                style={{
                                                    display: this.state.error
                                                        ? 'inherit'
                                                        : 'none'
                                                }}
                                            >
                                                {this.state.error}
                                            </Alert>

                                            <Box sx={{ minHeight: 310 }}>
                                                <List>{tipList}</List>
                                            </Box>

                                            <Divider />

                                            <Box
                                                sx={{
                                                    px: 0,
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        my: 1,
                                                        mx: 1,
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'space-between'
                                                    }}
                                                >
                                                    <Typography
                                                        variant="caption"
                                                        component="div"
                                                        sx={{}}
                                                    >
                                                        Total Odds
                                                    </Typography>

                                                    <Typography
                                                        variant="caption"
                                                        component="div"
                                                        sx={{
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {accumulator}
                                                    </Typography>
                                                </Box>

                                                {/* <FormControl
                                                        fullWidth
                                                        sx={{ my: 1 }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-amount">
                                                            Price
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            size="small"
                                                            id="outlined-adornment-amount"
                                                            value={
                                                                this.state
                                                                    .amount
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeAmount
                                                            }
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    Ksh
                                                                </InputAdornment>
                                                            }
                                                            label="Price"
                                                            type="number"
                                                            inputSizeSmall={
                                                                true
                                                            }
                                                            fullWidth={true}
                                                        />
                                                        <FormHelperText
                                                            error={
                                                                this.state
                                                                    .amountError !==
                                                                ''
                                                            }
                                                        >
                                                            {
                                                                this.state
                                                                    .amountError
                                                            }
                                                        </FormHelperText>
                                                    </FormControl> */}

                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    sx={{
                                                        fontStyle: 'italic',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    Base Price is{' '}
                                                    <strong>Ksh. 50</strong>
                                                </Typography>
                                            </Box>

                                            <Paper
                                                variant="outlined"
                                                sx={{ p: 1, m: 0 }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: 1
                                                    }}
                                                >
                                                    {/* <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disableElevation
                                                            onClick={
                                                                this
                                                                    .handleOnListPublish
                                                            }
                                                        >
                                                            Publish
                                                        </Button> */}

                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        disableElevation
                                                        loading={
                                                            this.state
                                                                .publishing
                                                        }
                                                        onClick={
                                                            this
                                                                .handleOnListPublish
                                                        }
                                                    >
                                                        Publish
                                                    </LoadingButton>

                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        disableElevation
                                                        onClick={
                                                            this
                                                                .handleOnListClear
                                                        }
                                                    >
                                                        Clear
                                                    </Button>
                                                </Box>
                                            </Paper>

                                            {/* <Alert severity="warning">
                                                    <AlertTitle>Info</AlertTitle>
                                                    <strong>
                                                    Total odds must be between 2.00 and 10. A minimum of 2.00 and a maximum of 10.00
                                                    </strong>

                                                    <br></br>
                                                    
                                                    <small>
                                                    This is to reduce the recent constant occurence of unrealistic odds.
                                                    </small>
                                                </Alert> */}
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    {/* </HContainer> */}
                </Box>
            </Box>
        );
    }
}

function AddTips(props) {
    const navigate = useNavigate();
    return <AddTipsComponent {...props} navigate={navigate} />;
}

export default withCookies(AddTips);
