import React from 'react';

import {
    Box,
    CssBaseline,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    ThemeProvider,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';

import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CasinoRoundedIcon from '@mui/icons-material/CasinoRounded';

import { theme } from '../MyTheme';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExitToApp } from '@mui/icons-material';
import { useCookies } from 'react-cookie';

import ThemeCustomization from '../themes';

const drawerWidth = 220;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

export default function MiniDrawer(props) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const createNavItem = (name, icon, path) => ({
        name: name,
        icon: icon,
        path: path,
        handleClick: () => navigate(path)
    });

    const createNavItemClick = (name, icon, path, handleClick) => ({
        name: name,
        icon: icon,
        path: path,
        handleClick: handleClick
    });

    const navLinks1 = [
        createNavItem('Dashboard', <DashboardIcon />, '/'),
        // createNavItem('Users', <PeopleIcon />, '/users'),
        createNavItem('Tips', <CasinoRoundedIcon />, '/tips'),
        createNavItem('Finances', <AccountBalanceWalletIcon />, '/finances'),
        createNavItem('Games', <SportsSoccerIcon />, '/games'),
        createNavItem('Subscriptions', <PeopleIcon />, '/subscriptions'),
    ];

    const navLinks2 = [
        createNavItem('Settings', <SettingsIcon />, '/settings'),
        createNavItemClick('Logout', <ExitToApp />, '/exit', (e) => {
            removeCookie('token');
            console.log('Removing');
            location.reload();
            window.location.reload();
        })
    ];

    const NavItems = ({ navList }) =>
        navList.map((item, index) => (
            <Tooltip
                title={item.name}
                placement="right"
                sx={{
                    visibility: 'hidden'
                }}
            >
                <ListItem
                    key={item.name}
                    disablePadding
                    sx={{
                        display: 'block'
                    }}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            m: 1,
                            borderRadius: 2
                        }}
                        selected={location.pathname === item.path}
                        onClick={item.handleClick}
                    >
                        <ListItemIcon
                            sx={{
                                minHeight: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center'
                            }}
                        >
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={item.name}
                            sx={{
                                opacity: open ? 1 : 0
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </Tooltip>
        ));

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <AppBar position="fixed" open={open} elevation={0}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && {
                                    display: 'none'
                                })
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            BetsCracker Admin
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <NavItems navList={navLinks1} />
                    </List>
                    <Divider />
                    <List>
                        <NavItems navList={navLinks2} />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3
                    }}
                >
                    <DrawerHeader />
                    {props.children}
                </Box>
            </Box>
        </React.Fragment>
    );
}
