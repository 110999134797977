import React from 'react';

import { Box, Typography, Tabs, Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import LatestTopTips from './LatestTopTips';
import AddFreeTips from './AddFreeTips';
import FreeTips from './FreeTips';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`stlip-manager-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `game-manager-tab-${index}`,
        'aria-controls': `slip-manager-tabpanel-${index}`
    };
}

class SlipManagerPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0
        };
    }

    handleChange(newValue) {
        this.setState({
            value: newValue
        });
    }

    render() {
        const { value } = this.state;

        const handleTabChange = (e, newValue) => {
            this.handleChange(newValue);
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* <Typography variant="h6" component="div">
                    Tips Manager
                </Typography> */}
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label="slip manager tabs"
                    >
                        <Tab label="Add Free Tips" />
                        <Tab label="Free Tips" />
                        <Tab label="Latest Tips" />
                        
                    </Tabs>
                </Box>
                
                <TabPanel index={0} value={value}>
                    <AddFreeTips />
                </TabPanel>
                <TabPanel index={1} value={value}>
                    <FreeTips />
                </TabPanel>
                <TabPanel index={2} value={value}>
                    <LatestTopTips />
                </TabPanel>
            </Box>
        );
    }
}

export default function SlipManager(props) {
    const navigate = useNavigate();
    return <SlipManagerPage navigate={navigate} {...props} />;
}
