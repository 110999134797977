import React from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import {
    AppBar,
    Box,
    Container,
    Paper,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import * as PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import PasswordInput from '../../components/PasswordInput';

import axios from '../../api/axios';

PasswordInput.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string
};

export default function Login(props) {
    const navigate = useNavigate();

    const [cookie, setCookie] = useCookies(['token', 'refresh']);

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    if (cookie.token) {
        navigate('/');
    }

    const handleLogin = () => {
        setLoading(true);
        setErrorMessage('');

        const options = {
            method: 'POST',
            url: '/api/account/login',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                username: username,
                password: password
            }
        };

        axios
            .request(options)
            .then((response) => {
                let data = response.data;
                setErrorMessage('Success');
                if (data['access']) {
                    setCookie('token', data['access']);
                    navigate('/');
                }
            })
            .catch(function (err) {
                console.log(err.response);
                setErrorMessage('Invalid Credentials');
            })
            .finally(function () {
                setLoading(false);
            });
    };

    const inputFontStyle = {
        fontWeight: '500',
        marginBottom: 5
    };

    const labelFontStyle = {
        fontWeight: '500'
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    return (
        <Box sx={{ flexGrow: 1, m: 0, p: 0, height: '100%' }}>
            <AppBar position="static" elevation={0}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        Jokubets Admin
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container sx={{ height: '100%', width: '100%', marginY: '5%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Paper
                        elevation={1}
                        sx={{
                            maxWidth: 500,
                            width: '100%'
                        }}
                    >
                        <form>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 5,
                                    gap: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography
                                        id="modal-modal-login-title"
                                        variant="h6"
                                        component="div"
                                        fontWeight={500}
                                    >
                                        Login
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        gap: 1,
                                        paddingX: 0,
                                        paddingY: 0
                                    }}
                                >
                                    <TextField
                                        label="Username"
                                        variant="outlined"
                                        size="small"
                                        InputLabelProps={{
                                            style: { ...labelFontStyle }
                                        }}
                                        inputProps={{
                                            style: { ...inputFontStyle }
                                        }}
                                        value={username}
                                        onChange={handleUsernameChange}
                                    />

                                    <PasswordInput
                                        label="Password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />

                                    <Box
                                        sx={{
                                            p: 0,
                                            m: 0,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            gap: 3
                                        }}
                                    >
                                        <LoadingButton
                                            loading={loading}
                                            variant="contained"
                                            sx={{ w: 1, minWidth: '100%' }}
                                            disableElevation
                                            onClick={handleLogin}
                                        >
                                            Login
                                        </LoadingButton>

                                        <Typography
                                            variant="caption"
                                            display="block"
                                            color="red"
                                        >
                                            {errorMessage}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
}
