import React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddGames from './GameManager/AddGames';
import PastGames from './GameManager/PastGames';
import GameSettings from './GameManager/GameSettings';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`game-manager-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export function a11yProps(index) {
    return {
        id: `game-manager-tab-${index}`,
        'aria-controls': `game-manager-tabpanel-${index}`
    };
}

class GameManagerPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(newValue) {
        this.setState({
            value: newValue
        });
    }

    render() {
        const { value } = this.state;

        const handleTabChange = (e, newValue) => {
            this.handleChange(newValue);
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="h6" component="div">
                    Games Manager
                </Typography>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label="game manager tabs"
                    >
                        <Tab label="Top Games" {...a11yProps(0)} />
                        <Tab label="Past Top Games" {...a11yProps(1)} />
                        <Tab label="Settings" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel index={0} value={value}>
                    <AddGames />
                </TabPanel>
                <TabPanel index={1} value={value}>
                    <PastGames />
                </TabPanel>
                <TabPanel index={2} value={value}>
                    <GameSettings />
                </TabPanel>
            </Box>
        );
    }
}

export default function GameManager(props) {
    const navigate = useNavigate();
    return <GameManagerPage navigate={navigate} {...props} />;
}
